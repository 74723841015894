interface SegmentWindow extends Window {
  analytics: SegmentAnalytics
}

interface SegmentAnalytics {
  page(): Function;
  track(title: string, payload: Object): Function;
  identify(payload: {name: string, email: string}): Function;
}


export class SegmentResource {
  window: SegmentWindow;
  constructor(){
    this.window = typeof window != 'undefined' ? window as unknown as SegmentWindow: null;
  }
  segmentTrack(title: string, payload: Object) {
    if(process.env.DOMAIN === 'perfocal.com') {
      this.window.analytics.track(
        title,
        payload
      )
    }
  }

  segmentLoad() {
    if(process.env.DOMAIN === 'perfocal.com') {
      this.window.analytics.page()
    }
  }

  segmentIdentify(name: string, email: string) {
    if(process.env.DOMAIN === 'perfocal.com') {
      this.window.analytics.identify({ name, email})
    }
  }

}