/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

type MetaProps = JSX.IntrinsicElements['meta'];

type HtmlProps = JSX.IntrinsicElements['html'] & {
  [key: string]: string;
};

interface Props {
  description?: string;
  lang?: HtmlProps | any;
  meta?: MetaProps[] | any;
  title: string;
}

export function Seo({ description, lang, meta, title }: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
    htmlAttributes={{
      lang,
    }}
    title={'New Booking | Perfocal'}
    titleTemplate={`New Booking | Perfocal`}
    meta={[
      {
        name: `description`,
        content: 'Book a professional photographer for anything, in just a few clicks.',
      },
      {
        property: `og:title`,
        content: 'Book a photoshoot | Perfocal',
      },
      {
        property: `og:description`,
        content: 'Book a professional photographer for anything, in just a few clicks.',
      },
      {
        property: `og:image`,
        content: 'https://dkk5oy4zs0vc4.cloudfront.net/jumpstart/img/heros/hero-4.jpg',
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: site.siteMetadata.author,
      },
      {
        name: `twitter:title`,
        content: 'Book a photoshoot | Perfocal',
      },
      {
        name: `twitter:image`,
        content: 'https://dkk5oy4zs0vc4.cloudfront.net/jumpstart/img/heros/hero-4.jpg',
      },
      {
        name: `twitter:description`,
        content: 'Book a professional photographer for anything, in just a few clicks.',
      },
    ].concat(meta)}
    >
      <link href="https://dkk5oy4zs0vc4.cloudfront.net/favicon.ico" rel="shortcut icon" type="image/x-icon"/>
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossOrigin="anonymous"/>
      <link rel="stylesheet" href="https://unpkg.com/flickity@2/dist/flickity.min.css"/>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      <link href="https://dkk5oy4zs0vc4.cloudfront.net/stack/css/stack-interface.css" rel="stylesheet" type="text/css" media="all"/>
      <link href="https://dkk5oy4zs0vc4.cloudfront.net/stack/css/iconsmind.css" rel="stylesheet" type="text/css" media="all" />
      <link href="https://dkk5oy4zs0vc4.cloudfront.net/stack/css/theme.css" rel="stylesheet" type="text/css" media="all"/>
      <link rel="stylesheet" href="https://use.typekit.net/bak6pol.css"/>
      <link rel="canonical" href="https://book.perfocal.com/" />
    </Helmet>
  );
}

Seo.defaultProps = {
  description: ``,
  lang: `en`,
  meta: [],
};
